import React from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
const Booking = () => {
  return (
    <Hero>
      <Banner title="Reserve your Table" subtitle="Please call 8220155582 / 8220155583 / 8220155584 or send an email to ayiniattil@gmail.com and book your tables.">
        <Link to="/" className="btn-primary">
          return home
        </Link>
      </Banner>
    </Hero>
  );
};

export default Booking;
