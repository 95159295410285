import React from "react";
//import Hero from "../components/Hero";
import CarouselA from "../components/CarouselA";
//import { Link } from "react-router-dom";
const Gallery = () => {
  return (
    <div>
      <CarouselA></CarouselA>
    </div>
      
  );
};

export default Gallery;
