import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//import bg1 from "../images/bg1.jpeg";
//import bg2 from "../images/bg2.jpeg";
//import bg3 from "../images/bg3.jpeg";
const CarouselA = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Set this to true if you want the autoplay functionality
    autoplaySpeed: 2100 // Set the autoplay speed (in milliseconds)
  };

  const imagePaths = ['/gallery/bg1.jpeg', '/gallery/bg2.jpeg', '/gallery/bg3.jpeg',
  '/gallery/bg4.jpeg', '/gallery/bg5.jpeg', '/gallery/bg6.jpeg',
  '/gallery/bg7.jpeg', '/gallery/bg8.jpeg', '/gallery/bg9.jpeg',
  '/gallery/bg10.jpeg', '/gallery/bg11.jpeg', '/gallery/bg12.jpeg',
  '/gallery/bg13.jpeg', '/gallery/bg14.jpeg', '/gallery/bg15.jpeg',
  '/gallery/bg16.jpeg', '/gallery/bg17.jpeg', '/gallery/bg18.jpeg',
  '/gallery/bg19.jpeg', '/gallery/bg20.jpeg', '/gallery/bg21.jpeg',
  '/gallery/bg22.jpeg', '/gallery/bg23.jpeg', '/gallery/bg24.jpeg',
  '/gallery/bg25.jpeg', '/gallery/bg26.jpeg', '/gallery/bg27.jpeg',
  '/gallery/bg28.jpeg', '/gallery/bg29.jpeg', '/gallery/bg30.jpeg'];


  return (
    <Slider {...settings}>
      {/*<div style={{width: '100%'}}>
        <img style={{width: '100%'}} src={bg1} alt="Slide1" />
      </div>
      <div style={{width: '100%'}}>
      < img style={{width: '100%'}} src={bg2} alt="Slide2" />
      </div>
      <div style={{width: '100%'}}>  
        <img style={{width: '100%'}} src={bg3} alt="Slide3" />
  </div>*/}

    {imagePaths.map((path, index) => (
        <div key={index} style={{width: '100vw',height: '100vh'}}>
          <img style={{width: '100vw',height: '100vh'}} src={path} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </Slider>
  );
};

export default CarouselA;
