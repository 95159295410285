import React, {useState, useEffect} from "react";
import Hero from "../components/Hero";
import AdBanner from "../components/AdBanner";
//import Services from "../components/Services";
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  useEffect(() => {
    const sprayColors = () => {
      const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00']; // Replace with your desired colors

      for (let i = 0; i < 50; i++) {
        const spray = document.createElement('div');
        spray.className = 'color-spray';
        spray.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
        document.body.appendChild(spray);

        setTimeout(() => {
          document.body.removeChild(spray);
        }, 0); // Duration of the spray effect in milliseconds
      }
    };

    sprayColors();
  }, []);

  return (
    <>
      <Hero>
        {
          <div className="home">
            {showBanner && <AdBanner onClose={handleCloseBanner} />}
          </div>}
      </Hero>
      {/*<Services />*/}
      {/*<FeaturedRooms />*/}
    </>
  );
};

export default Home;
