import React from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
const Contact = () => {
  return (
    <Hero>
      <Banner title="Contact Us" subtitle="AYINI ATTIL
        Plot No 1820 1821 JOHNSON NAGAR, SIVANTHIPATTI ROAD,
        THIYAGARAJA NAGAR,
        TIRUNELVELI - 627011 Phone:8220155582 / 8220155583 / 8220155584
        Email: ayiniattil@gmail.com"
        >
        <Link to="/" className="btn-primary">
          return home
        </Link>
      </Banner>
    </Hero>
  );
};

export default Contact;
