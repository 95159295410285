import React from 'react';
import bannerImg1 from "../images/banner1.jpeg";
import bannerImg2 from "../images/banner2.jpeg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const AdBanner = ({ onClose }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 my-2">
                    <div className="row mt-5" style={{'position':'relative'}}>
                        <div className="col-md-6 my-2">
                            <img width="100%" src={bannerImg1} alt=""></img>
                            {/*<button onClick={onClose} className="overlay-icon" >
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>*/}
                        </div>
                        <div className="col-md-6 my-2">
                            <img width="100%" src={bannerImg2} alt=""></img>
                            {/*<button onClick={onClose} className="overlay-icon" >
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>*/}
                        </div>
                        <button onClick={onClose} className="overlay-icon" >
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    );
};

export default AdBanner;
